<script setup lang="ts">
import { ReportUIConfigActionType } from '@reports/constants'
import type { ReportTableColumn } from '../types'
// import { createActionUrl } from './utils'
import { getPageDestination } from '@reports/composables/useApiReportsRun/utils'
import TextOverflow from './Shared/TextOverflow.vue'
import type { AbstractReportUIConfigAction } from '@reports'
import classNames from 'classnames'

const props = defineProps<ReportTableColumn>()

const additionalData = props.item.additionalData ?? {}

const actionData = props.item?.[props.id]?.[props.uiConfig?.action?.key] ?? {}

// ACTION - TOOLTIP
const hasActionTooltip =
  props.uiConfig?.action?.type === ReportUIConfigActionType.TOOLTIP &&
  props.uiConfig.action.component &&
  Object.entries(actionData).length > 0

const isActionTooltipVisible = ref(false)
const onFocus = () => {
  isActionTooltipVisible.value = true
}
const onBlur = () => {
  isActionTooltipVisible.value = false
}

// ACTION - LINK
const url = getPageDestination(props.uiConfig?.action, additionalData)

// TEMPLATE
const [DefineTemplate, ReuseTemplate] = createReusableTemplate()
const sharedClasses = classNames(
  'flex',
  'max-w-full',
  'items-center',
  hasActionTooltip ? '' : '',
)
</script>

<template>
  <DefineTemplate>
    <Icon
      v-if="props.uiConfig?.icon"
      :name="props.uiConfig.icon"
      class="mr-1"
    />
    <Icon
      v-else-if="props.uiConfig?.action?.type === ReportUIConfigActionType.LINK"
      name="link"
      class="mr-1"
    />

    <TextOverflow :column="column">
      <slot>{{ value.value }}</slot>
    </TextOverflow>

    <!-- ACTION - TOOLTIP -->
    <LazyReportsTableCellSharedActionTooltip
      v-if="hasActionTooltip"
      :component="(uiConfig!.action as AbstractReportUIConfigAction).component"
      v-bind="{
        data: actionData,
        visible: isActionTooltipVisible,
      }"
    />
  </DefineTemplate>

  <!-- LINK -->
  <NuxtLink
    v-if="url"
    :to="url"
    :class="[
      sharedClasses,
      'hover:text-primary underline-offset-2 hover:underline hover:decoration-2',
    ]"
    @mouseover="onFocus"
    @focus="onFocus"
    @mouseout="onBlur"
    @blur="onBlur"
  >
    <ReuseTemplate />
  </NuxtLink>
  <!-- BUTTON -->
  <span
    v-else
    :class="sharedClasses"
    tabindex="0"
    role="button"
    @mouseover="onFocus"
    @focus="onFocus"
    @mouseout="onBlur"
    @blur="onBlur"
    @keydown.enter="onFocus"
  >
    <ReuseTemplate />
  </span>
</template>
