import { pages } from '../constants'
import type { ReportCell, ReportUIConfigAction } from '@reports'
import { ReportUIConfigActionType } from '@reports/constants'

export const getPageDestination = (
  action: ReportUIConfigAction | undefined,
  additionalData: ReportCell<any> | undefined,
): string | undefined => {
  if (action?.type !== ReportUIConfigActionType.LINK) return

  const { destination } = action ?? {}
  const pageTemplate = pages[destination]

  if (!pageTemplate) {
    console.error(`Page template for destination "${destination}" not found.`)
    return
  }

  return pageTemplate.replace(/\${(.*?)}/g, (_, key) => {
    const value = additionalData?.[key]
    if (value === undefined) {
      console.error(`Placeholder "${key}" not found in additionalData.`)
      return 'undefined'
    }
    return value
  })
}
